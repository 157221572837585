import * as React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import NormalIcon from './icons/NormalIcon';
import { ICONS_NAMES } from './icons/iconsData';
import Image from 'react-graceful-image';

export default function Header() {
  return (
    <Box sx={{ flexGrow: 1 }} style={{ padding: '10px 0px', marginTop: '25vh' }}>
      <Image
        src='images/logo.png'
        width='100'
        height='100'
        noLazyLoad
        alt='Anitar.dev'
        customPlaceholder={(ref) => (
          <div ref={ref} style={{ width: '100px', height: '100px', textAlign: 'center', margin: 'auto' }}>
            <NormalIcon
              size='100'
              name={ICONS_NAMES['Dog']}
              color='#ffffff'
              margin='0px'
              marginLeft='0px'
              marginRight='0px'
            />
          </div>
        )}
      />
      <Typography variant='h4' style={{ margin: '10PX 0PX' }}>
        Use avatars placeholders for your web development, easy.
      </Typography>
    </Box>
  );
}
