import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import CustomCard from './custom/CustomCard';
import NormalIcon from './icons/NormalIcon';
import { ICONS_NAMES } from './icons/iconsData';
import Image from 'react-graceful-image';
function getRandomArray() {
  return Array(4)
    .fill()
    .map(() => Math.round(Math.random() * 2000));
}

function Example(props) {
  const [randomNumbers, setRandom] = React.useState(getRandomArray());

  const shuffle = () => {
    setRandom(getRandomArray());
  };

  return (
    <div style={{ margin: '10px auto', padding: '0px 5px' }}>
      <CustomCard style={{ background: '#505050', padding: '0px 5px', borderRadius: '90px' }}>
        <Grid container alignContent='center' alignItems='center'>
          {(isWidthUp('sm', props.width) ? randomNumbers : randomNumbers.slice(2)).map((number) => {
            return (
              <Grid item xs={6} sm={3} md={3} key={number} justifyContent='center'>
                <Image
                  src={`https://anitars-5f5b7.web.app/get/${number}/80`}
                  width='80'
                  height='80'
                  noLazyLoad
                  alt='My awesome avatar'
                  customPlaceholder={(ref) => (
                    <div ref={ref} style={{ width: '80px', height: '84px', textAlign: 'center', margin: 'auto' }}>
                      <NormalIcon
                        size='80'
                        name={ICONS_NAMES['Dog']}
                        color='#BCE2D1'
                        margin='0px'
                        marginLeft='0px'
                        marginRight='0px'
                      />
                    </div>
                  )}
                />
              </Grid>
            );
          })}
        </Grid>
      </CustomCard>
      <Button
        onClick={shuffle}
        size='small'
        //disableElevation
        style={{
          color: '#ffffff',
          background: '#2AA7B8',
          boxShadow: '1px 1px 5px #ffffff',
          fontSize: '25px',
          padding: '3px 5px 0px 10px',
          margin: '25px auto 10px',
          borderRadius: '45px',
        }}>
        Shuffle <NormalIcon color='#ffffff' name={ICONS_NAMES['Refresh']} />
      </Button>
    </div>
  );
}

export default withWidth()(Example);
