import * as React from 'react';
import { Chip, Grid, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const CustomChip = styled(Chip)({
  background: '#505050',
  color: '#ffffff',
  fontSize: '25px',
  padding: '10px',
  '&:hover': {
    color: '#505050',
  },
});

export default function Credits() {
  return (
    <div style={{ margin: '150PX 0PX' }}>
      <Typography variant='h3' style={{ marginBottom: '20PX' }}>
        Photos Credits
      </Typography>

      <Grid
        container
        spacing={1}
        style={{ maxWidth: '700px', margin: 'auto', width: '100%' }}
        alignContent='space-between'
        justifyContent='center'
        justifyItems='center'>
        <Grid item>
          <CustomChip
            label='Dag Knudsen'
            clickable
            onClick={() => window.open('http://www.dag-knudsen.com/', '_blank').focus()}
          />
        </Grid>
        <Grid item>
          <CustomChip
            label='Tim Flach'
            clickable
            onClick={() => window.open('https://timflach.com/', '_blank').focus()}
          />
        </Grid>
        <Grid item>
          <CustomChip
            label='Chris Brunskill'
            clickable
            onClick={() => window.open('https://www.chrisbrunskill.co.uk/', '_blank').focus()}
          />
        </Grid>
        <Grid item>
          <CustomChip
            label='Caymia'
            clickable
            onClick={() => window.open('https://www.dreamstime.com/caymia_info', '_blank').focus()}
          />
        </Grid>
        <Grid item>
          <CustomChip
            label='Fredrik Solli Wandem'
            clickable
            onClick={() => window.open('https://www.linkedin.com/in/fredrikwandem', '_blank').focus()}
          />
        </Grid>
      </Grid>
    </div>
  );
}
