import React from 'react';

interface IconProps {
  icon: string;
  color?: string;
  size?: number;
  viewbox?: number;
  margin?: number;
  marginLeft?: number;
  marginRight?: number;
  verticalAlign?: boolean;
  verticalFlip?: boolean;
  altText?: string;
  ref?: any;
}

export default function Icon(props: IconProps) {
  const styles = {
    svg: {
      display: 'inline-block',
      marginTop: props.margin || '5px',
      marginBottom: props.margin || '5px',
      marginLeft: props.marginLeft || '5px',
      marginRight: props.marginRight || '5px',
      verticalAlign: props.verticalAlign ? 'center' : '',
      transform: props.verticalFlip ? 'rotate(180deg)' : '',
    },
    path: {
      fill: props.color || '#000000',
    },
  };
  return (
    <svg
      //x="0" y="0"
      ref={props.ref}
      style={styles.svg}
      width={`${props.size || '20'}px`}
      height={`${props.size || '20'}px`}
      viewBox={`0 0 ${props.viewbox || '24'} ${props.viewbox || '24'}`}
      preserveAspectRatio='none'>
      {props.altText && <title>{props.altText}</title>}
      <path style={styles.path} d={props.icon}></path>
    </svg>
  );
}
