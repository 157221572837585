import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

//import reportWebVitals from './reportWebVitals';

// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: 'AIzaSyBJ-F7FUJcDP2ep7XrSkgDLyyWLX7fZIyk',
  authDomain: 'anitars-5f5b7.firebaseapp.com',
  projectId: 'anitars-5f5b7',
  storageBucket: 'anitars-5f5b7.appspot.com',
  messagingSenderId: '88142657342',
  appId: '1:88142657342:web:c263d630747013c3ac9e6f',
  measurementId: 'G-TK0T8KLPX5',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
