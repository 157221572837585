import React from 'react';

import { ICONS } from './iconsData';
import Icon from './Icon';

interface NormalIconProps {
  name: string;
  color?: string;
  size?: number;
  viewBox?: number;
  margin?: number;
  marginLeft?: number;
  marginRight?: number;
  vertical?: boolean;
  flip?: boolean;
  alt?: string;
  ref?: any;
}

export default function NormalIcon(props: NormalIconProps) {
  return (
    <Icon
      icon={ICONS[props.name]}
      viewbox={props.viewBox}
      color={props.color}
      size={props.size}
      margin={props.margin}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      verticalAlign={props.vertical}
      verticalFlip={props.flip}
      altText={props.alt}
      ref={props.ref}
    />
  );
}
