import React, { Suspense } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Example from './components/Example';
import Header from './components/Header';
import Credits from './components/Credits';
const Examples = React.lazy(() => import('./components/Examples'));
const About = React.lazy(() => import('./components/About'));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'Yanone Kaffeesatz',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className='App' style={{ textAlign: 'center' }}>
        <Header />
        <Example />
        <Suspense fallback={<div style={{ height: '150px' }} />}>
          <Examples />
        </Suspense>

        <Credits />

        <Suspense fallback={<div />}>
          <About />
        </Suspense>
      </div>
    </ThemeProvider>
  );
}

export default App;
