import { styled } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const CustomCard = styled(Card)({
  background: 'rgb(80 80 80)',
  borderRadius: '90px',
  boxShadow: '0 1px 2px 1px rgba(122, 178, 153,0.3)',
  color: 'white',
  padding: '30px 20px 30px',
  maxWidth: '600px',
  margin: 'auto',
  textAlign: 'center',
});

export default CustomCard;
